import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, ActivatedRoute} from '@angular/router'

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, public router: Router ) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }
    donate() {
        this.router.navigate(['/donate']);

    }
}
