//import * as html2canvas from 'html2canvas';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
//import * as html2canvas from 'html2canvas';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
    UserDetails: any;
    TotalAmount: any;
    TotalAmountinWords: any;
    Date: any;
    @ViewChild('cardContent') cardContent: ElementRef; // Change to match your HTML element
    currentDate: string = new Date().toLocaleDateString();
    amount: number = 100;
    SubscriptionType: any;

    constructor(private viewportScroller: ViewportScroller) {
        debugger
        this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
        this.TotalAmount = localStorage.getItem("TotalAmount");
        this.Date = localStorage.getItem("Date");
        this.SubscriptionType = localStorage.getItem("SubscriptionType");
        localStorage.setItem("ReceiptViewed", "True");
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        console.log('cardContent:', this.cardContent); // Check if cardContent is defined
    }

    downloadAsPDF() {
        const element = this.cardContent.nativeElement;

        html2canvas(element).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            // Add the image to the PDF with correct arguments
            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), canvas.height * (pdf.internal.pageSize.getWidth() / canvas.width));

            pdf.save('receipt.pdf');
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    }


}
