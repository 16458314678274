import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../../general.service';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    enquiery: any = [];
    HomeUrl: any;
    Fullname: any;
    email: any;
    Mobile: any;
    subject: any;
    Message: any;
    constructor( private viewportScroller: ViewportScroller, public generalService: GeneralService, private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {


    }


    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }
   
    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var subject = form.subject;
        console.log(subject);
        
        var message = form.message;
        console.log(message);
    }
    async sendmail() {
        try {
            // Validate inputs
            if (!this.Fullname || !this.email || !this.Mobile || !this.Message || !this.subject) {
                this.generalService.ShowAlert("ERROR", 'All fields are required', 'error');
                return;
            }

            // Prepare enquiry data
            let arr = [];
            arr.push({
                FarmerName: this.Fullname,
                EmailID: this.email,
                Comments: this.Message,
                Subject: this.subject,
                Mobile: this.Mobile
            });

            console.log("Enquiry Data:", JSON.stringify(arr, null, 2)); // Debug

            // Create FormData for the request
            const uploadfile = new FormData();
            uploadfile.append("Email", JSON.stringify(arr));

            // Define URL
            const url = "api/Ss/EnquiryMailTo_SS";

            // Immediately show a loading message
            this.generalService.ShowAlert("INFO", 'Submitting your enquiry Please Wait...', 'info');

            // Send data asynchronously
            const response = await this.generalService.PostData(url, uploadfile);

            // Check if response is successful
            if (response) {
                // Show success message
                this.generalService.ShowAlert("SUCCESS", 'Mail sent successfully', 'success');

                // Clear form fields
                this.Fullname = '';
                this.Mobile = '';
                this.email = '';
                this.Message = '';
                this.subject = '';
            } else {
                throw new Error('Mail submission failed');
            }
        } catch (error) {
            console.error("Error sending mail:", error);
            this.generalService.ShowAlert("ERROR", 'Failed to send mail. Please try again.', 'error');
        }
    }

}
