<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">About Us</b></li>
            <div>About Us </div>
        </ul>
    </div>
</nav>
<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>SubhaSankalpam</span></h2>
            <p>Where did it all start ? Here's the beginning of our story</p>
        </div>
        <div class="row">
            <p>
                <strong>
                    In the year 2019, a profound encounter left an indelible mark on one of the founders as they embarked on
                    a work-related journey.
                </strong>
            </p>
            <p>
                What started as a response to a singular, heartfelt plea has since evolved into a collective effort to address
                a systemic challenge. The commitment to serving homemade food remains at the core of their mission, but the
                realization that the problem is more complex than initially perceived has fueled a determination to advocate
                for change, raise awareness, and implement sustainable solutions.
            </p>

            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <br />
                        <!--<h2>We Are Digital <span>Marketing & Branding</span></h2>-->
                        <p>
                            <strong>
                                Through their ongoing efforts, this dedicated group aims to make a lasting impact on the lives of
                                those affected by hunger, one homemade meal at a time.
                            </strong>
                        </p>
                        <p>
                            In was the year 2019, when I was traveling for work, an old lady approached me and asked me for food.
                            It is evident that she did not have food for the past couple of days. As it was late in the night,
                            I bought some bread and packaged food and offered her. The response that I heard from her "Would you mind
                            serving some rice son?". I felt helpless as it was late in the night there were no restaurants around and
                            my home was quite far. Since then I started serving people fresh, homemade food prepared and delivered from
                            home.As we keep serving, I have realized that the problem is much bigger and needs attention.
                            The statistics from GHI proved to me that the problem is much bigger than what I perceived it to be.
                        </p>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-video img_1" style="">
                    <img src="../../../../assets/img/about/aboutimg.jpg" alt="about" style="border-radius: 5px;" />
                    <!--<img src="assets/img/about.jpg" alt="about">-->
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
            <p>As this compassionate initiative unfolded, it became increasingly evident that the scope of the problem extended far beyond the initial encounter. The stark reality of widespread hunger and food insecurity began to unfold, challenging preconceived notions and underscoring the urgency of the mission. Motivated by a desire to make a meaningful impact, the group delved into understanding the scale of the issue, relying on statistics such as those from the Global Hunger Index (GHI) to illuminate the gravity of the situation.</p>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="ico">
                        <i class="fa fa-eye"></i>
                    </div>
                    <h3 class="title">Vision</h3>
                    <p>
                        At SubhaSankalpam Foundation, our primary mission is to address the pressing issue of hunger.
                        We passionately work towards providing fresh and nutritious meals to those who need it the most.
                        Through our unwavering commitment, we aim to raise awareness, inspire action, and empower
                        individuals to create a meaningful difference in their communities.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="ico">
                        <i class="fa fa-bullseye"></i>
                    </div>
                    <h3 class="title">Mission</h3>
                    <p>
                        Our mission is clear: To ensure that no one goes to bed HUNGRY. We believe that access to
                        fresh and healthy food is a basic human right that everyone deserves. Through our collective
                        efforts, we strive to alleviate hunger and its associated challenges, such as malnutrition
                        and food insecurity.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-title">
            <h2>Few facts about<span>hunger</span></h2>
            <p>Global Hunger Index (GHI)</p>
        </div>
        <div class="row">
            <p>
                <strong>
                    Global Hunger Index (GHI) is a tool for comprehensively measuring and tracking hunger at global,
                    regional, and national levels.
                </strong>
            </p>
            <p>
                GHI scores are based on the values of four components – Undernourishment, Child Wasting, Child Stunting,
                and Child Mortality. GHI scores are calculated yearly to assess progress and setbacks in combating hunger.
            </p>

            <div class="col-lg-5 col-md-12">
                <div class="about-video img_2" style="">
                    <img src="../../../../assets/img/about/ghi.png" alt="about" style="border-radius: 5px;" />
                    <!--<img src="assets/img/about.jpg" alt="about">-->
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <br />
                        <!--<h2>We Are Digital <span>Marketing & Branding</span></h2>-->
                        <p>
                            GHI scores are based on the values of four components – Undernourishment, Child Wasting, Child
                            Stunting, and Child Mortality. GHI scores are calculated yearly to assess progress and setbacks
                            in combating hunger.
                        </p>
                        <p>
                            The 2023 Global Hunger Index gives India a rank of 111th out of 125 countries. This indicates a hunger severity level in the country. With a score of 28.7, India has a level of hunger that is serious.
                            The GHI is designed to raise awareness and understanding of the struggle against hunger, provide a way to compare levels of hunger between countries and regions, and call attention to those areas of the world where hunger levels are highest and where the need for additional efforts to eliminate hunger is greatest.
                        </p>
                        <p>
                            I decided to do it at scale and join hands with people who are connected to the problem of hunger and willing to contribute towards a sustainable solution for the problem.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
<style>
    .img_2 {
        width: 100%;
        float: inline-end;
        margin-top: 2rem;
    }
    @media only screen and (max-width:600px){
        .card {
            display: inline-block;
            margin: 50px 8px;
            text-align: center;
            background-color: #FFFFFF;
            padding: 30px 20px 40px;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(45, 62, 80, 0.3);
            height: 485px;
        }
    }
</style>
