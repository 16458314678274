<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h1><span>Subhasankalpam Foundation</span></h1>
                <p>Join us in our mission to make a difference. Whether through donations, volunteering, or spreading awareness, your contribution matters. Together, we can inspire action and empower individuals to create positive change in the lives of those facing food insecurity.</p>
                <ul class="pt-2">
                    <li><a href="https://www.facebook.com/profile.php?id=100088499520137&mibextid=ZbWKwL" target="_blank" class="fab fa-facebook-f"></a></li>
                    <li><a href="https://www.instagram.com/subhasankalpamfoundation?igsh=MWgzMjZ1ZnBlMTNzdw==" target="_blank" class="fab fa-instagram"></a></li>
                    <li><a href="https://youtube.com/@subhasankalpam174?si=yqu1IxpZWQNQ-7WI" target="_blank" class="fab fa-youtube"></a></li>
                </ul>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-4 ">
                        <!--<!--   <h4 class="pb-3">Useful Links</h4>-->
                        <div><a href="/home-one">Home</a></div>
                        <div><a href="about">About Us </a></div>
                        <!--<div><a href="">Our Activities</a></div>-->
                        <div><a href="">Donate </a></div>
                        <div><a href="Gallery">Gallery </a></div>
                        <!--<div><a href="#">Video Gallery </a></div>-->
                        <div><a href="/home-one">FAQ'S </a></div>
                        <div><a href="Blogs"> Latest News & Updates</a></div>
                        <div><a href="contact">Contact Us</a></div>
                    </div>
                    <div class="col-lg-4 ">
                        <!--<h4 class="pb-3">Useful Links</h4>-->
                        <div><a href="privacypolicy.html" target="_blank">Privacy Policy</a></div>
                        <div><a href="termsandconditions.html" target="_blank">Terms And Conditions </a></div>
                        <div><a href="refundpolicy.html" target="_blank">Refund Policy</a></div>
                    </div>
                    <div class="col-lg-4">
                        <h4 class="pb-3">Contact Us</h4>
                        <address>
                            Subhasankalpam Foundation<br />
                            PlatNO 10 New SBH Colony<br />
                            Hyderabad,<br />
                            Telangana,India 50026<br />


                            <button class="btn1" type="button" onclick="window.location.href='tel:+918328080097'">+91 8328080097</button>

                            <button class="btn1 mt-2" type="button" onclick="window.location.href='tel:+917794916299'">+91 7794916299</button>
                            <div><strong>Email:</strong> <span style="font-size:13px;"><a href="mailto:Mail2subhasankalpam@gmail.com" target="_blank"> Mail2subhasankalpam@gmail.com</a></span></div>
                        </address>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <!--<h3><a routerLink="/"><span>X</span>avro</a></h3>-->

        <div>© Subhasankalpam is Powered by <a href="https://gagri.net//" target="_blank">Gagri Global IT Solutions Pvt Ltd.</a></div>
    </div>
</footer>
<footer class="mobile-footer">
    <ul class="social-icons">
        <li><a href="tel:+918328080097"><i class="fa-solid fa-phone-volume"></i></a></li>
        <li><a href="mailto:Mail2subhasankalpam@gmail.com"><i class="fa-solid fa-envelope"></i></a></li>
        <li><a href="https://api.whatsapp.com/send?phone=918328080097&text=%20Hello%20Subhasankalpam%20Foundation%20Team&source=&data=%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20target="><i class="fa-brands fa-whatsapp"></i></a></li>
        <li><a href="#"><img src="assets\img\icns\donate3.png" class="socialimg " /></a></li>
    </ul>
</footer>
<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
