<nav aria-label="breadcrumb" class="breadcrumb"style="padding:10px;">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Blogs</b></li>
            <div>Blogs </div>

        </ul>
    </div>
</nav>
<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Our News</h4>
            <h2>Latest Blog <span>Posts</span></h2>
            <p>
                "Empathy in Action: Exploring the Impact of Our Latest Blog Posts on Groceries, Blankets, Water Dispensers, and Books Donation"
            </p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Food Donation</span>
                        <h3><a routerLink="/">The Impact of Food Donation on Community Well-being</a></h3>
                        <p>In a world where abundance coexists with scarcity, the act of food donation emerges as a powerful force, capable of bridging gaps and nourishing not just bodies but also spirits. This blog delves into the transformative impact of food donation, exploring how a simple act of kindness can create a ripple effect that resonates throughout the community.</p>
                        <a routerLink="/" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>Water Dispenser</span>
                        <h3><a routerLink="/">Quenching Thirst, Sustaining Health: The Elegance of the Water Dispenser</a></h3>
                        <p>In the pursuit of a healthier, more sustainable lifestyle, the importance of staying adequately hydrated cannot be overstated. Enter the unsung hero of hydration – the water dispenser. In this blog, we explore the multifaceted benefits and the elegant simplicity that water dispensers bring to our daily lives.</p>
                        <a routerLink="/" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span>Blankets Donation</span>
                        <h3><a routerLink="/"> "Wrapped in Warmth: The Heartwarming Impact of Blanket Donations"</a></h3>
                        <p>As the chill of winter descends, so does the need for warmth and comfort. In the spirit of giving, blanket donations emerge as a beacon of hope, providing not just physical warmth but also a tangible expression of compassion. In this blog, we delve into the profound impact of blanket donations and how they weave a tapestry of care for those in need.</p>
                        <a routerLink="/" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>Books Donation</span>
                        <h3><a routerLink="/">"Gift of Knowledge: The Impact of Books Donation on Minds and Communities"</a></h3>
                        <p>In a world that thrives on the exchange of ideas and knowledge, the act of donating books becomes a powerful catalyst for positive change. In this blog, we explore the transformative impact of books donation, shedding light on how each donated book contributes to the enrichment of minds and the building of vibrant, empowered communities.</p>
                        <a routerLink="/" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Groceries Donation</span>
                        <h3><a routerLink="/"> "From Shelves to Hearts: The Compassionate Impact of Groceries Donation"</a></h3>
                        <p>In a world marked by both abundance and need, groceries donation stands as a powerful expression of empathy and community support. This blog delves into the meaningful impact of donating groceries, exploring how these contributions go beyond sustenance to touch the lives of individuals and families facing food insecurity.</p>
                        <a routerLink="/" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
