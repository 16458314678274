<div class="subscribe-area ptb-100">
    <div class="container">
        <div style="text-align:right;padding-top:5%">
            <button (click)="downloadAsPDF()">Download</button>
        </div>
        <div class="newsletter receipt" id="cardContent" #cardContent>
            <div>
                <img src="../../assets/img/lo.jpeg" width="50" />
            </div>
            <br />
            <h2>Receipt</h2>
            <div class="row">
                <div class="col-lg-6 col-xs-6">
                    <div>Donation Date</div>
                    <div>Name</div>
                    <div>Mobile</div>
                    <div>Mail</div>
                    <div>Address</div>
                    <div>Donate for</div>
                    <div>Amount</div>
                    <div>Subscription Type</div>
                </div>
                <div class="col-lg-6 col-xs-6">
                    <div>{{Date | date:'MMM d, yy, h:mm a'}}</div>
                    <div>Mr/Ms <b>Sri {{UserDetails[0].Username}}</b></div>
                    <div>{{UserDetails[0].Mobile}}</div>
                    <div>{{UserDetails[0].MailID}}</div>
                    <div>{{UserDetails[0].Address}}, {{UserDetails[0].Pincode}}</div>
                    <div>SubhaSankalpam Foundation</div>
                    <div>{{TotalAmount | currency:'INR'}}</div>
                    <div>{{SubscriptionType}}</div>
                </div>

               
            </div>
            <!--<form class="newsletter-form">
            <input type="email" class="form-control" placeholder="Your Email Address">
            <button class="btn btn-primary" type="submit">Subscribe</button>
        </form>-->
        </div>
    </div>
</div>

