<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
             
            <h2>Welcome to <span>SubhaSankalpam</span></h2>
            <p>A thought for a good cause.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Subhasankalpam Foundation</h3>
                    <p>“A foundation for a social cause driven for change across communities”.</p>
                    <a title="Read More" class="link-btn"routerLink="/about"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <!--<h3>Friendly Codes</h3>-->
                    <p>At Foundation, we are dedicated to addressing this pressing issue of hunger by providing fresh and nutritious meals to those in need. </p>
                    <a title="Read More" class="link-btn"routerLink="/about"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <!--<h3>Fast Support</h3>-->
                    <p>We endeavor to Raise Awareness, Inspire Action, and Empower Individuals to make a difference across communities.</p>
                    <a title="Read More" class="link-btn"routerLink="/about"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
