import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
//import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    HomeURL: any;
    url: any;
    urlname: any;
    categoryId: any = []
    prodData: any = []
  //  private client = new BehaviorSubject<any>('');
  //  clientData = this.client.asObservable();
  //  public categoryIDarr = new BehaviorSubject<any>([]);
   // public productArr = new BehaviorSubject<any>([]);

    constructor(public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
    }

 
    /*
     Below method is for getting category id from throug services
    */

    /*
      Below method is for setting category id from throug services
     */
   
    
    /*
     Below method is for getting Products from throug services
    */
   

    /*
     Below method is for setting Products from throug services
    */
    


    ShowAlert(title, text, type) {
        Swal.fire({
            title: title,
            html: text,
            //icon: type,
            confirmButtonText: 'Ok'
        })
    }
    ShowAlert1(title, text, type) {
        Swal.fire({
            title: title,
            html: text,
            //icon: type,
            confirmButtonText: 'Print',
            //confirmButtonText1: 'Cancel'
        })
    }
    //Global Method to retrieve data 
    GetData(url) {
        return new Promise((resolve, reject) => {

            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                this.url = this.urlname + url
                this.http.get(this.url).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData(url, PostParam) {

        return new Promise((resolve, reject) => {

            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                url = data.Webservice + url
                this.http.post(url, PostParam).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData2(url, PostParam, PostParam2) {
        ;
        return new Promise((resolve, reject) => {
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
                url = data.Webservice + url
                this.http.post(url, PostParam, PostParam2).subscribe(data => {
                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
}
