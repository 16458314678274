<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Activities</span></h2>
           <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Food Donation</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2"> Blankets Donation</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Groceries Donation</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Books Donation</label>
            <!-- Tab 5 -->
            <input type="radio" name="tabset" id="tab5" aria-controls="Groceries">
            <label for="tab5">Water Dispenser</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/subhasankalpam/IMG-20240118-WA0032.webp" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                             
                                <p>Food donation is often born out of a recognition of need and a desire to make a positive
                                difference. It begins with individuals who witness the stark realities of hunger and food insecurity
                                in their communities.</p>
                                <p>It could be a shared meal with someone in need or a realization that surplus food can become a lifeline for those less fortunate. Whatever the catalyst, the genesis of giving lies in empathy and a commitment to uplifting the community.</p>

                                <p><strong>Food donation has the power to unite communities, fostering a sense of shared responsibility and interconnectedness. </strong></p>
                                <p>Whether through local initiatives or collaborations with established organizations, communities come together to address the needs of their most vulnerable members.</p>
    <!--<ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/subhasankalpam/IMG-20240118-WA0067.webp" alt="img">

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <p><strong>Blanket donations are more than a simple act of charity; they represent a gesture of humanity extended through every thread and stitch.</strong></p>
                                <p>As temperatures drop, these cozy coverings become a lifeline for those experiencing homelessness, refugees seeking solace, or families facing financial hardships. <strong>Each donated blanket is a warm embrace that says, "You are not alone; we care."</strong></p>
                                <p>Winter's bite can be harsh, particularly for those without the means to ward off the cold. </p>
                                <p>Blanket donations serve as protective shields, offering a barrier against the elements and safeguarding vulnerable individuals from the harsh realities of winter weather. </p>
                                <!--<ul>
        <li><i class="fas fa-check"></i> Creative Design</li>
        <li><i class="fas fa-check"></i> Retina Ready</li>
        <li><i class="fas fa-check"></i> Responsive Design</li>
        <li><i class="fas fa-check"></i> Modern Design</li>
        <li><i class="fas fa-check"></i> Awesome Design</li>
    </ul>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="../../../../assets/img/about/grocessary.jpg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <!--<h3>Experienced staff</h3>-->

                                <p>Groceries are more than a collection of items on a shopping list; they represent the promise of nourishment and sustenance. When donated, they become a lifeline for those grappling with food insecurity, offering not just meals but a glimmer of hope.</p>
                                <p>Each item contributes to the assurance that, even in challenging times, there is support and care available.</p>
                                <p>Groceries donation serves as a bridge between those with the means to give and those in need. In local communities, these donations foster a sense of interconnectedness and shared responsibility.</p>
                                <p><strong>It's a tangible way for individuals, businesses, and organizations to come together, transcending socio-economic boundaries to address a fundamental human need – access to food.</strong></p>

                            </div>
                        </div>
                    </div>
                </div>
               
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="../../../../assets/img/about/booksdonation.jpg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <!--<h3>24/7 support </h3>-->
                                <p>Books have an unparalleled ability to transport readers to different worlds, unlocking imagination and expanding horizons. When you donate books, you offer more than just printed pages; you provide the key to new realms of knowledge, creativity, and understanding. </p>
                                <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>-->
                                <p> Each book becomes a portal to a universe waiting to be explored, fostering a love for learning and intellectual curiosity.</p>
                                <p>Books donation nurtures a culture of reading that extends beyond the act of giving. As books find new homes, they inspire a love for literature and learning. </p>
                                <p>This ripple effect fosters a community where reading is not just a solitary activity but a shared passion, creating a positive cycle of knowledge dissemination and intellectual growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Groceries" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/subhasankalpam/IMG-20240118-WA0034.webp" alt="img">

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <p><strong>Amidst the hustle and bustle of modern life, it's easy to overlook the simple yet essential act of staying hydrated.</strong></p>
                                <p>Water dispensers usher in a hydration revolution by making access to clean, refreshing water a seamless part of our routines. No more fumbling with bottles or waiting for the kettle to boil – the dispenser stands ready to quench your thirst at the touch of a button.</p>
                                <p>Imagine a workplace where the water cooler is not just a source of hydration but a hub of social interaction.</p>
                                <p>Water dispensers create communal spaces where colleagues gather, fostering a sense of camaraderie and well-being. Whether it's the office break room or a shared space in your home, these dispensers transform hydration into a social experience.</p>

                                <!--<h3>24/7 support1</h3>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
