

    <nav aria-label="breadcrumb" class="breadcrumb" style="padding:10px;">
        <div class="container-fulid"  >
            <ul>
                <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home/</b></a></li>
                <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Donation</b></li>
                <div>Donation</div>

            </ul>
        </div>
    </nav>
    <div class="container-fluid" style=" background-color: aliceblue !important;">
        <div id="team" class="team-area ptb-100 mt-n3">
            <div class="container">
                <div class="topcontent">
                    <strong>
                        By donating to the SubhaSankalpam Foundation, you directly support our efforts in
                        providing fresh food to those in need.
                    </strong>
                    <div class="hidecontent">
                        Your monetary contributions go towards
                        sourcing fresh ingredients, maintaining distribution networks, and expanding our reach to
                        serve more communities.
                    </div>
                </div>
                <p class="hidecontent">
                    Your monetary contributions serve as the lifeblood of our organization, empowering us to source the finest and freshest
                    ingredients for our food distribution initiatives.
                </p>      <br />

                <div class="tab-wrap">
                    <input type="radio" name="tabs" id="tab1" checked>
                    <div class="tab-label-content" id="tab1-content">
                        <label for="tab1" (click)="OpenMonthlytime()">Monthly Payment</label>
                        <div class="tab-content">
                            <div class="card">
                                <div class="top-left">Amount</div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div>Indian Rupee (INR)</div>

                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" placeholder="200"  [(ngModel)]="RecurringAmount"  />
                                    </div>

                                </div>
                            </div>
                            <!--<div class="card">-->
                                <!--<form   class="contact-inform form">-->
                                    <!--<div class="top-left">Amount</div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div>Indian Rupee (INR)</div>

                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Amount" [(ngModel)]="RecurringAmount" />
                                        </div>-->
                                        <!--<div class="col-lg-6">
                                            <div>Enter UPI ID</div>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter UPI ID" [(ngModel)]="UserVPA" />
                                        </div>
                                        <div class="col-lg-6">
                                            <div>Select Recuuring Period</div>
                                        </div>
                                        <div class="col-lg-6">
                                            <select id="typeId" style="cursor:pointer" [(ngModel)]="SelectedTimePeriod" (change)="SelectRecurring()" placeholder="Select Recuuring Period" required>
                                                <option *ngFor="let obj of TimePeriods" [value]="obj.value">{{ obj.recurring }} </option>
                                            </select>
                                        </div>-->
                                    <!--</div>-->
                                <!--</form>-->
                            <!--</div>-->

                            <div class="card">
                                <form [formGroup]="myForm">
                                    <div class="top-left left">Personal Details</div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Your Name" formControlName="UserName" minlength="3" />
                                            <small class="text-danger" *ngIf="myForm.get('UserName').touched">
                                                <span *ngIf="myForm.get('UserName').hasError('required')">Name is required</span>
                                                <span *ngIf="myForm.get('UserName').hasError('minlength')">Name must be atleast 3 characters</span>
                                            </small>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Phone Number" formControlName="Mobile" maxlength="10" minlength="10" />
                                            <small class="text-danger" *ngIf="myForm.get('Mobile').touched">
                                                <span *ngIf="myForm.get('Mobile').hasError('required')">Mobile is required</span>
                                                <span *ngIf="myForm.get('Mobile').hasError('minlength')">Enter valid mobile number</span>
                                            </small>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Email Address" formControlName="EMail" />
                                            <small class="text-danger" *ngIf="myForm.get('EMail').touched">
                                                <span *ngIf="myForm.get('EMail').hasError('required')">Email is required</span>
                                                <span *ngIf="myForm.get('EMail').errors?.email">Invalid email address</span>
                                            </small>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="card">
                                <form [formGroup]="myForm">
                                    <div class="top-left">Address</div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Address" formControlName="Address" />
                                            <!--<small class="text-danger" *ngIf="myForm.get('Address').touched">
                                                <span *ngIf="myForm.get('Address').hasError('required')">Address is required</span>
                                            </small>-->
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Pincode" formControlName="Pincode" maxlength="6" minlength="6" />
                                            <small class="text-danger" *ngIf="myForm.get('Pincode').touched">
                                                <!--<span *ngIf="myForm.get('Pincode').hasError('required')">Pincode is required</span>-->
                                                <span *ngIf="myForm.get('Pincode').hasError('minlength')">Enter valid Pincode</span>
                                            </small>
                                        </div>

                                        <div class="col-lg-12">
                                            <div>Enter Complete Address for Postal Communication</div>

                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div style="text-align:center">
                                <form (ngSubmit)="AddUsersdata()">
                                    <button type="submit" (ngSubmit)="AddUsersdata()" class="button-29">Submit</button>
                                </form>
                            </div>


                        </div>
                    </div>









                    <input type="radio" name="tabs" id="tab2">
                    <div class="tab-label-content" id="tab2-content">
                        <label for="tab2" (click)="OpenOnetime()">One Time Payment</label>
                        <div class="tab-content">
                            <div class="card">
                                <div class="top-left">Amount</div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div>Indian Rupee (INR)</div>

                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" placeholder="Enter Amount" [(ngModel)]="RecurringAmount" />
                                    </div>

                                </div>
                            </div>

                            <div class="card">
                                <form [formGroup]="myForm">
                                    <div class="top-left left">Personal Details</div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Your Name" formControlName="UserName" minlength="3" />
                                            <small class="text-danger" *ngIf="myForm.get('UserName').touched">
                                            <span *ngIf="myForm.get('UserName').hasError('required')">Name is required</span>
                                            <span *ngIf="myForm.get('UserName').hasError('minlength')">Name must be atleast 3 characters</span>
                                        </small>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Phone Number" formControlName="Mobile" maxlength="10" minlength="10" />
                                            <small class="text-danger" *ngIf="myForm.get('Mobile').touched">
                                            <span *ngIf="myForm.get('Mobile').hasError('required')">Mobile is required</span>
                                            <span *ngIf="myForm.get('Mobile').hasError('minlength')">Enter valid mobile number</span>
                                        </small>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Email Address" formControlName="EMail" />
                                            <small class="text-danger" *ngIf="myForm.get('EMail').touched">
                                            <span *ngIf="myForm.get('EMail').hasError('required')">Email is required</span>
                                            <span *ngIf="myForm.get('EMail').errors?.email">Invalid email address</span>
                                        </small>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <div class="card">
                                <form [formGroup]="myForm">
                                    <div class="top-left left">Address</div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Address" formControlName="Address" />
                                            <!--<small class="text-danger" *ngIf="myForm.get('Address').touched">
                                                <span *ngIf="myForm.get('Address').hasError('required')">Address is required</span>
                                            </small>-->
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" placeholder="Enter Pincode" formControlName="Pincode" maxlength="6" minlength="6" />
                                            <small class="text-danger" *ngIf="myForm.get('Pincode').touched">
                                                <!--<span *ngIf="myForm.get('Pincode').hasError('required')">Pincode is required</span>-->
                                                <span *ngIf="myForm.get('Pincode').hasError('minlength')">Enter valid Pincode</span>
                                            </small>
                                        </div>

                                        <div class="col-lg-12">
                                            <div>Enter Complete Address for Postal Communication</div>

                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div style="text-align:center">
                                <form (ngSubmit)="AddUsersdata()">
                                    <button type="submit" class="button-29" (ngSubmit)="AddUsersdata()">Submit</button>
                                </form>
                            </div>


                        </div>
                    </div>


                </div>

                <!--<div class="slide"></div>-->
            </div>


        </div>
        <!--<div style="text-align:center;width:50%">
            <button type="submit" class="button-29" (click)="OneTimeRazorPayPayment()">Razor Pay</button>
            <br /><br /><button type="submit" class="button-29" (click)="createSubscription()">Create Subscription</button>
        </div>
        <br />-->
    </div>




