import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { ReplaySubject, Observable, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { GeneralService } from '../../../general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
    dataResult: any;
    arr: any = [];
    HomeUrl: any;
    constructor(private viewportScroller: ViewportScroller, public generalService: GeneralService, public http: HttpClient,
        @Inject(DOCUMENT) private readonly document: any) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }
    
  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }
    
    ngOnInit() {
        this.GetCategory();
    }


    lazyLoadLibrary(resourceURL): Observable<any> {
        return forkJoin([
            this.loadScript(resourceURL)
        ]);
    }

    private loadScript(url: string): Observable<any> {
        if (this._loadedLibraries[url]) {
            return this._loadedLibraries[url].asObservable();
        }

        this._loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = () => {
            this._loadedLibraries[url].next();
            this._loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);
        return this._loadedLibraries[url].asObservable();
    }   
    GetCategory() {
        debugger

        // Call Gettoken and await its completion
       
            this.arr = [];
            this.arr.push({
                // EmpId: this.loginDet.EmpId,
                // TokenId: this.loginDet.TokenId,
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

         var url =  "api/Ss/SS_Gallery_CRUD";
           

        // Use HttpHeaders in the request
        this.generalService.PostData(url, UploadFile).then(data => {
                debugger


                this.dataResult = data;
            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        
    }

}
