<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How You Can  <span>Get Involved</span></h2>
            <h4></h4>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Volunteer Opportunities</h3>
                    <p>Whether you have a passion for cooking, organizing events, or simply want to lend a helping hand, we offer a range of volunteer opportunities. From serving meals at community kitchens to assisting in food drives, your contribution, no matter how big or small, is valued and appreciated.</p>

                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Donate</h3>
                    <p>By donating to the SubhaSankalpam Foundation, you directly support our efforts in providing fresh food to those in need. Your monetary contributions go towards sourcing fresh ingredients, maintaining distribution networks, and expanding our reach to serve more communities.</p>

                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Spread the Word</h3>
                    <p>
                        Join us in the fight against hunger! Spread awareness by sharing our website and engaging on social media.

                        Make a difference – help combat hunger. Organize public events and encourage others to join our cause.

                        Create a ripple effect of change! Inspire others to bring in three like-minded individuals and amplify our message to end hunger.
                    </p>

                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Together, We Can Make a Difference</h3>
                    <p>At SubhaSankalpam Foundation, we firmly believe that no single entity can solve the issue of hunger alone. It is through collective action and a shared commitment that we can create lasting change. Join us in our MISSION to ensure that fresh, nutritious food reaches every person in need. Together, we can make hunger a thing of the past!</p>

                </div>
            </div>
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>We are unique</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>

                </div>
            </div>-->
        </div>
    </div>
</div>



<div class="container">
    <div class=" row">
        <div class="col-lg-6">
            <div class="section-title">
                <h4>Donattion</h4>
                <h2>Spread the<span>Word</span></h2>
            </div>
            <p>
                <strong>
                    At the heart of SubhaSankalpam Foundation lies a profound commitment to alleviating hunger and nourishing
                    communities in need.
                </strong> Your generous donations play a pivotal role in fueling our mission to provide not
                    just sustenance, but the gift of fresh, wholesome food to those who need it the most.
            </p>
            <p>
                By choosing to support SubhaSankalpam Foundation, you are directly impacting the lives of individuals and families
                facing food insecurity.

                <a style="float: inline-end;"routerLink="/" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>

            </p>

        </div>
        <div class="col-lg-6">
            <img src="assets\img\subhasankalpam\IMG-20240118-WA0047.webp" style="border-radius:5px;"/>
        </div>
        </div>

    </div>
