import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GeneralService } from 'src/app/general.service';
import { Router } from '@angular/router';
declare var Checkout: any;
declare var $: any;
declare var Razorpay: any;
import { PricingComponent } from '../../common/pricing/pricing.component';


@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
    myForm: FormGroup;
    TaxnId: any;
    url: any;
    merchantUserId: any;
    merchantSubscriptionId: any;
    authRequestId: any;
    TransactionID: any;
    TimePeriods: any; SelectedTimePeriod: any=24;
    RecurringAmount: any = 1;
    TotalAmount: any;
    UserVPA: any;
    Flag1: any; Flag2: any = 1;
    UserName: any;
    MobileNumber: any;
    UserEmail: any;
    UserAddress: any; UserPincode: any;
    UserDetails: any;
    Date: any;
    RecurringValue: any = 24;
    ReceiptViewed: any;
    TransStatus: any;newsubscription:any;
    SubscripID:any;

    razorpayResponse:any;
    
    constructor(private http: HttpClient, private fb: FormBuilder, public generalService: GeneralService,    
        public router: Router,
        private razorpayService: PricingComponent,
    ) {
        this.myForm = this.fb.group({
            // Define your form controls here
            UserName: ['', Validators.required],
            Mobile: ['', Validators.required],
            EMail: ['', Validators.required],
            Pincode: ['', ],
            Address: ['', ]
        });

        this.TimePeriods = [
            { recurring: '6 Months', value: 6 },
            { recurring: '1 Year', value: 12 },
            //{ recurring: '1 Year 6 Months', value: 18 },
            { recurring: '2 Years', value: 24 },
            //{ recurring: '2 Year 6 Months', value: 30 }
        ]

        this.Date = new Date();
        debugger
        if (this.Flag1 == 1) {
            this.RecurringAmount = 1;
        } else if (this.Flag2 == 1) {
            this.RecurringAmount = 200;
        }
        this.ReceiptViewed = localStorage.getItem("ReceiptViewed");
        if (this.ReceiptViewed != "True") {
            this.Gettransactionstatus();
            this.NotifyRecurringDebit();
            //this.RecurringDebitExecuteStatus();
        } else if (this.ReceiptViewed == "True") {
            localStorage.removeItem("ReceiptViewed");
            localStorage.removeItem("TransId");
            localStorage.removeItem("RecurringPaymentAmount");
            localStorage.removeItem("authRequestId");
            localStorage.removeItem("merchantSubscriptionId");
            localStorage.removeItem("TransactionID");
            localStorage.removeItem("merchantUserId");
        }               
    }

    ngOnInit() {
        this.lazyLoadScript('https://checkout.razorpay.com/v1/checkout.js').then(() => {
            console.log('Razorpay script loaded successfully.');
        }).catch(error => {
            console.error('Error loading Razorpay script:', error);
        });

        // Initialize form controls
        this.UserName = this.myForm.controls['UserName'].value;
        this.MobileNumber = this.myForm.controls['Mobile'].value;
        this.UserEmail = this.myForm.controls['EMail'].value;
        this.UserAddress = this.myForm.controls['Address'].value;
        this.UserPincode = this.myForm.controls['Pincode'].value;
    }

    lazyLoadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Script load error for ${src}`));
            document.head.appendChild(script);
        });
    }


    OpenOnetime() {
        debugger
        this.Flag1 = 1;
        this.Flag2 = 0;
        this.RecurringAmount = 1;
    }
    OpenMonthlytime() {
        debugger
        this.Flag2 = 1;
        this.Flag1 = 0;
        this.RecurringAmount = 200;
    }

    SelectRecurring() {
        //debugger
        this.RecurringValue = this.SelectedTimePeriod;
        this.RecurringValue = parseInt(this.RecurringValue);
        //console.log(this.RecurringValue);
    }

                    

    AddUsersInfo(val: any) {
        debugger        
        this.RecurringAmount = parseInt(this.RecurringAmount);
        if (this.RecurringAmount >= 200) {
            if (((this.UserVPA != undefined || this.UserVPA != null) ?? this.Flag2 == 1) || (this.Flag1 == 1 ?? (this.UserVPA == undefined || this.UserVPA == null) )) {
                if (this.myForm.valid) {
                    var obj = [{

                        Username: val.UserName,
                        Mobile: val.Mobile,
                        MailID: val.EMail,
                        Address: val.Address,
                        Pincode: val.Pincode

                       
                    }]
                         
                    var UploadFile = new FormData();
                    UploadFile.append("Details", JSON.stringify(obj));
                    var url = "api/Ss/Insert_Userdetails";
                    this.generalService.PostData(url, UploadFile).then((data: any) => {
                        //debugger
                        if (data == "SUCCESS") {
                            if (this.Flag1 == 1) {
                                //this.OneTimePayment();
                                this.OneTimeRazorPayPayment();
                                localStorage.setItem("SubscriptionType", "One Time Subscription");
                            } else if (this.Flag2 == 1) {
                                this.MobileNumber = val.Mobile;
                                this.createSubscription();
                                //this.RecurringPayment();
                                this.generalService.ShowAlert("Success", "A Payment Request is sent to your UPI ID", "Success");
                                localStorage.setItem("SubscriptionType", "Recurring Subscription");
                            }
                            this.UserDetails = JSON.stringify(obj);
                            localStorage.setItem("UserDetails", this.UserDetails);
                            localStorage.setItem("TotalAmount", this.RecurringAmount);
                            localStorage.setItem("Date", this.Date);                            
                        }
                    })
                } else {
                    this.generalService.ShowAlert("Alert", "Please enter all fields...", "error");
                }
            } else {
                this.generalService.ShowAlert("Alert", "Please enter UPI ID.", "error");
            }
        } else {
            this.generalService.ShowAlert("Alert", "Sorry, the minimum donation amount is 200 rupees.", "error");
        }
    }

    AddUsersdata() {
        debugger
        if (this.myForm.valid) {
            if (this.Flag1 == 1) {
                if (this.RecurringAmount >= 1) {
                    localStorage.setItem("SubscriptionType", "One Time Subscription");                    
                    this.OneTimeRazorPayPayment();
                } else {
                    this.generalService.ShowAlert("Alert", "Sorry, the minimum donation amount is one rupee.", "error");
                }
            } else if (this.Flag2 == 1) {
                localStorage.setItem("SubscriptionType", "Recurring Subscription");
                //this.createSubscription();
                this.createRazorpayPlan();
            }
        } else {
            this.generalService.ShowAlert("Alert", "Please enter all fields...", "error");
        }
    }

    InsertUsers() {
      debugger
      this.UserName = this.myForm.controls['UserName'].value;
      this.MobileNumber = this.myForm.controls['Mobile'].value;
      this.UserEmail = this.myForm.controls['EMail'].value;
      this.UserAddress = this.myForm.controls['Address'].value;
      this.UserPincode = this.myForm.controls['Pincode'].value;
      var obj = [{
          Username: this.UserName,
          Mobile: this.MobileNumber,
          MailID: this.UserEmail,
          Address: this.UserAddress,
          Pincode: this.UserPincode,
          SubscriptionID: this.SubscripID
      }]           
      var UploadFile = new FormData();
      UploadFile.append("Details", JSON.stringify(obj));
      var url = "api/Ss/Insert_Userdetails";
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger
          //if (data == "SUCCESS") {
            localStorage.setItem("UserID", data);
            this.InsertTransactions();
            this.UserDetails = JSON.stringify(obj);
          localStorage.setItem("UserDetails", this.UserDetails);
          //if (this.Flag1 == 1) {
          //    localStorage.setItem("TotalAmount", this.RecurringAmount);
          //} else if (this.Flag2 == 1) {
          //    localStorage.setItem("TotalAmount", "200");
          //}
            localStorage.setItem("TotalAmount", this.RecurringAmount);
            localStorage.setItem("Date", this.Date);
            this.SendMailAfterPayment();
        //}
      })
    }
    InsertTransactions() {
        debugger
        var UserID = localStorage.getItem("UserID");
        var PaymentID = localStorage.getItem("PaymentID");
        var obj = [{
            TransactionID: PaymentID,
            Amount: this.RecurringAmount,
            UserID: UserID,
            TransactionStatus: "Success"            
        }]
        var UploadFile = new FormData();
        UploadFile.append("Details", JSON.stringify(obj));
        var url = "api/Ss/Insert_TransactionDetails";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

        })
    }

    OneTimeRazorPayPayment() {
        debugger
        this.UserName = this.myForm.controls['UserName'].value;
        this.MobileNumber = this.myForm.controls['Mobile'].value;
        this.UserEmail = this.myForm.controls['EMail'].value;
        this.TotalAmount = parseInt(this.RecurringAmount) * 100; 
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        var options: any = {
            description: 'SubhaSankalpam Foundation',
            image: 'https://subhasankalpam.in/assets/img/subhasankalpam/logo.webp',
            currency: 'INR',
            //key: 'rzp_test_PKZRt4NGyfabME',// Test key
            //key: 'rzp_live_LnpQ4EQWubWJOo',// Ggari key
            key: 'rzp_live_thds8aczXTtqmb',// Subhasankalpam key
            payment_id: n,
            //payment_capture: '1',
            amount: parseInt(this.RecurringAmount) * 100,
            //callback_url:"https://subhasankalpam.in/",
            name: "SubhaSankalpam Foundation",
            prefill: {
                email: this.UserEmail,
                contact: +91 + "" + this.MobileNumber,
                name: this.UserName
            },
            theme: {
                color: '#008cb3'
            },
            handler: this.razorPayHandler.bind(this) // Define handler function
        }
        debugger
        var rzp1 = new Razorpay(options);
        //handler = this.razorPaySuccessHandler.bind(this);
        rzp1.open();
        //rzp1.on();
    }

    // Define handler function to capture payment response
    razorPayHandler(response: any) {
        console.log(response); // Log the response object to console
        debugger
        // Here you can handle the payment response as per your requirement
        if (response.razorpay_payment_id) {
            // Payment successful            
            localStorage.setItem("PaymentID", response.razorpay_payment_id);
            console.log('Payment successful:', response.razorpay_payment_id);
            this.InsertUsers();
            // You can trigger further actions or update UI accordingly
        } else {
            // Payment failed or was cancelled
            console.log('Payment failed or cancelled.');
        }
    }

    public razorPaySuccessHandler(response) {
       console.log(response);
       this.razorpayResponse = `Razorpay Response : ` + response.razorpay_payment_id;
       //this.showModal = true;
       //this.cd.detectChanges();
       console.log('Razorpay Response :' + response.razorpay_payment_id)
       //document.getElementById('razorpay-response').style.display = 'block';
     }


    createRazorpayPlan() {
        debugger
        this.TotalAmount = parseInt(this.RecurringAmount) * 100;
        //const apiUrl = "https://localhost:44326/api/razorpay/createPlan";
        const apiUrl = "https://subhasankalpam.in/webservices/api/razorpay/createPlan";
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        var PlanRequest = {
            amount: this.TotalAmount
        }
        return this.http.post<any>(apiUrl, PlanRequest, { headers: httpHeaders }).toPromise().then(response => {
            debugger
            console.log('Plan created:', response);
            const planid = response.Attributes.id;
            localStorage.setItem('PlanID', planid);            
            this.createSubscription();
            return response;
          })
          .catch(error => {
            console.error('Error creating plan:', error);
            throw error;
          });
    }



    createSubscription() {
        debugger
        this.TotalAmount = parseInt(this.RecurringAmount) * 100;
        var PlanID = localStorage.getItem("PlanID");
        //const apiUrl = "https://localhost:44326/api/razorpay/createSubscription";
        const apiUrl = "https://subhasankalpam.in/webservices/api/razorpay/createSubscription";
        //const apiUrl = "http://subhasankalpam.gagriglobal.com/webservices/api/razorpay/createSubscription";
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',            
        });
        var SubscriptionRequest = {
            amount: this.TotalAmount,
            planID: PlanID
        }
        return this.http.post<any>(apiUrl, SubscriptionRequest, {headers: httpHeaders}).toPromise().then(response => {
                debugger
                console.log('Subscription created:', response);
                this.storeSubscriptionDetails(response.Attributes);
                const id = response.Attributes.id;
                localStorage.setItem('SubscriptionID', id);
                this.RazorpayRecurringPayment();
                return response;
            })
            .catch(error => {
                console.error('Error creating subscription:', error);
                // Display user-friendly error message
                this.generalService.ShowAlert("Error", "Failed to create subscription. Please try again.", "error");
                throw error;
            });
    }

    RazorpayRecurringPayment() {
        debugger
        this.UserName = this.myForm.controls['UserName'].value;
        this.MobileNumber = this.myForm.controls['Mobile'].value;
        this.UserEmail = this.myForm.controls['EMail'].value;
        this.SubscripID = localStorage.getItem("SubscriptionID");
        const d = new Date(), n = d.getTime();
        this.TaxnId = n;       
        const options: any = {
            description: 'SubhaSankalpam Foundation',
            image: 'https://subhasankalpam.in/assets/img/subhasankalpam/logo.webp',
            //currency: 'INR',
            subscription_id: this.SubscripID,
            //key: 'rzp_test_zdJNe0nIC9lgXR',// Test key
            //key: 'rzp_live_LnpQ4EQWubWJOo',// Ggari key
            key: 'rzp_live_thds8aczXTtqmb',// Subhasankalpam key
            //payment_id: n,
            //payment_capture: '1',
            //amount: 500,
            name: "SubhaSankalpam Foundation",
            prefill: {
                email: this.UserEmail,
                contact: +91 + "" + this.MobileNumber,
                name: this.UserName
            },
            theme: { color: '#008cb3' },
            handler: this.razorPayHandler.bind(this) // Define handler function
        };
        const recrzp1 = new Razorpay(options);
        recrzp1.open();        
    }


    SendMailAfterPayment() {
        debugger
        this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
        var TotalAmount = localStorage.getItem("TotalAmount");
        var SubscriptionType = localStorage.getItem("SubscriptionType");
        var UploadFile = new FormData();
        UploadFile.append("UserDetails", JSON.stringify(this.UserDetails));
        UploadFile.append("TotalAmount", TotalAmount);
        UploadFile.append("SubscribeType", SubscriptionType);
        var url = "api/Ss/SendMailAfterPayment";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
        })
        this.router.navigate(['Receipt']);   
    }

 private storeSubscriptionDetails(Attributes:any) {
    // Example: Store the subscriptionId in localStorage
     localStorage.setItem('subscriptionDetails', JSON.stringify(Attributes));
  }

  getStoredSubscriptionId(): string | null {
    // Example: Retrieve the stored subscriptionId from localStorage
    return localStorage.getItem('Id');
  }

async createRazorpayPlan1() {
debugger
    const url = 'http://localhost:44326/api/razorpay/createPlan';
    const requestBody = {
userId: '123456'
}; // Add request body if needed

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add other headers if needed
            },
            body: JSON.stringify(requestBody), // Convert object to JSON string
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); // Parse JSON response
        console.log('Response from server:', data);
        // Handle response data as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error
    }
}



    OneTimePayment() {
        //debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        this.TotalAmount = parseInt(this.RecurringAmount) * 100; 
        //const apiUrl = "https://localhost:44326/api/Ss/PhonepeMakePayment";
        const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeMakePayment"; // Replace with your actual API URL
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VERIFY': 'df7191d8bd4018e6b2d00fc4b1b5dbe912efaa24a97e951d47f49e81f4a13930###1',
        }); // Replace with your headers
        const dynamicCallbackUrl = "https://subhasankalpam.in/";
        // Construct the payment request
        var paymentRequest = {
            merchantId: 'SUBHASANONLINE',
            merchantTransactionId: this.TaxnId,
            merchantUserId: 'SUBHASANONLINE123',
            amount: this.TotalAmount,
            //amount: '100',
            redirectMode: 'REDIRECT',
            redirectUrl: dynamicCallbackUrl,
            //callbackUrl: dynamicCallbackUrl,
            mobileNumber: this.MobileNumber,
            paymentInstrument: {
                type: "PAY_PAGE"
            }
        }        
        this.http.post(apiUrl, paymentRequest, { headers: httpHeaders }).subscribe(
            //Success callback function
            (response: any) => {
                //debugger                
                var redirectUrl = response.data.instrumentResponse.redirectInfo.url;                                             
                window.open(redirectUrl);                                
                localStorage.setItem("TransId", this.TaxnId);                
                window.location.reload();
                this.Gettransactionstatus();
            }, (error: any) => {
                console.error('API error:', error);
                //handle the error here
            }
        )
    }

    Gettransactionstatus() {
        debugger
        var TransId = localStorage.getItem("TransId");
        var TotalAmount = localStorage.getItem("TotalAmount");
        if (TransId != null) { 
        //const apiUrl = "https://localhost:44326/api/Ss/CheckPaymentStatus?merchantTransactionId=" + TransId;
        const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/CheckPaymentStatus?merchantTransactionId=" + TransId; // Replace with your actual API URL
        this.http.get(apiUrl).subscribe((response: any) => {
           // debugger
            var result = response.data.state
            this.TransStatus = result;
            if (result == "COMPLETED" || result == "COMPLETED ") {
                this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
                var UploadFile = new FormData();
                UploadFile.append("UserDetails", JSON.stringify(this.UserDetails));
                UploadFile.append("TotalAmount", TotalAmount);
                UploadFile.append("SubscribeType", 'One Time Subscription');
                var url = "api/Ss/SendMailAfterPayment";
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                })
                this.router.navigate(['Receipt']); 
            }
        })
        }
    }


    RecurringPayment() {
        debugger
        this.SubscripID = localStorage.getItem("SubscriptionID");
        var merchantUserId = Math.floor(100000000 + Math.random() * 900000000);
        this.merchantUserId = "SSF" + merchantUserId;
       // this.merchantSubscriptionId = "SSF" + this.merchantUserId + "12345";
        this.merchantSubscriptionId = this.SubscripID;
        this.TotalAmount = parseInt(this.RecurringAmount) * 100;
        localStorage.setItem("RecurringPaymentAmount", this.TotalAmount);
      //  const apiUrl = "https://localhost:44326/api/Ss/PhonepeRecurringPayment";
       const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeRecurringPayment"; // Replace with your actual API URL        
        const dynamicCallbackUrl = "https://subhasankalpam.in";
        // Construct the payment request
        var paymentRequest =
        {
            merchantId: "SUBHASANONLINE",
            merchantSubscriptionId: this.SubscripID,
            merchantUserId: this.merchantUserId,
            authWorkflowType: "TRANSACTION",	          //PENNY_DROP or TRANSACTION
            amountType: "FIXED",		          //FIXED or VARIABLE
            amount: this.TotalAmount,
            //amount: '100',
            //redirectMode: 'POST',
            frequency: "MONTHLY",
            recurringCount: this.RecurringValue,
            mobileNumber: this.MobileNumber           
        }        
        this.http.post(apiUrl, paymentRequest).subscribe(
            //Success callback function
            (response: any) => {
                //debugger                
                var state = response.data.state;
                if (state == "CREATED") {
                var subscriptionId = response.data.subscriptionId;                                                             
                localStorage.setItem("merchantSubscriptionId", subscriptionId);
                localStorage.setItem("merchantUserId", this.merchantUserId);
                this.FirstPayment();
                }                
            }, (error: any) => {
                console.error('API error:', error);
                //handle the error here
            }
        )
    }

    FirstPayment() {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.authRequestId = "SSF" + n;
        var merchantSubscriptionId = localStorage.getItem("merchantSubscriptionId");
        var merchantUserId = localStorage.getItem("merchantUserId");
        var randomnumber = Math.floor(100000000 + Math.random() * 900000000);
        var authRequestId = randomnumber + "12345";
     this.SubscripID = localStorage.getItem('SubscriptionID');
        var TotalPaidAmount = localStorage.getItem("RecurringPaymentAmount");
     //   const apiUrl = "https://localhost:44326/api/Ss/PhonepeFirstPayment";
        const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeFirstPayment"; // Replace with your actual API URL        
        var paymentRequest = {
            merchantId: "SUBHASANONLINE",
            merchantUserId: merchantUserId,
            subscriptionId: this.SubscripID,
            authRequestId: this.authRequestId,
            amount: TotalPaidAmount,
            paymentInstrument: {
                type: "UPI_COLLECT",                
                vpa: this.UserVPA
            }
        }
        this.http.post(apiUrl, paymentRequest).subscribe((response: any) => {
            //debugger
            var result = response.code;
            if (result == "SUCCESS") {
                localStorage.setItem("authRequestId", this.authRequestId);
                this.FirstPaymentStatus();
            }
        })
    }

    FirstPaymentStatus() {
        debugger        
        var authRequestId = localStorage.getItem("authRequestId");
        if (authRequestId != null) { 
     //   const apiUrl = "https://localhost:44326/api/Ss/PhonepeFirstPaymentStatus?authRequestId=" + authRequestId;
        const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeFirstPaymentStatus?authRequestId=" + authRequestId; // Replace with your actual API URL        
        this.http.get(apiUrl).subscribe((response: any) => {
            //debugger
            var result = response.data.subscriptionDetails.state
            this.TransStatus = result;
            if (result == "CREATED" || result == "ACTIVE") {               
                this.NotifyRecurringDebit();
            }
        })
        }
    }

    NotifyRecurringDebit() {
        debugger
        var merchantSubscriptionId = localStorage.getItem("merchantSubscriptionId");
        var merchantUserId = localStorage.getItem("merchantUserId");
        var TotalAmount = localStorage.getItem("TotalAmount");
      this.SubscripID = localStorage.getItem('SubscriptionID');
        var TotalPaidAmount = localStorage.getItem("RecurringPaymentAmount");
        if (merchantUserId != null) {
        var d = new Date(),
            n = d.getTime();
        this.TransactionID = n;       
     //   const apiUrl = "https://localhost:44326/api/Ss/PhonepeNotifyRecurringDebit";
       const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeNotifyRecurringDebit"; // Replace with your actual API URL        
        var paymentRequest = {
            merchantId: "SUBHASANONLINE",
            merchantUserId: merchantUserId,
            subscriptionId:  this.SubscripID,
            transactionId: this.TransactionID,
            autoDebit: "true",
            amount: TotalPaidAmount           
        }
        this.http.post(apiUrl, paymentRequest).subscribe((response: any) => {
            //debugger
            var result = response.data.state
            if (result == "ACCEPTED") {                
                var notificationId = response.data.notificationId;
                localStorage.setItem("TransactionID", this.TransactionID);
                localStorage.setItem("notificationId", notificationId);

                this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
                var UploadFile = new FormData();
                UploadFile.append("UserDetails", JSON.stringify(this.UserDetails));
                UploadFile.append("TotalAmount", TotalAmount);
                UploadFile.append("SubscribeType", 'Recurring Subscription');
                var url = "api/Ss/SendMailAfterPayment";
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                })
                this.router.navigate(['Receipt']);  
                //this.RecurringDebitExecute();
            }
        })
        }
    }

    RecurringDebitExecute() {
        debugger        
        var transactionID = localStorage.getItem("TransactionID");
        var notificationId = localStorage.getItem("notificationId");
        var merchantSubscriptionId = localStorage.getItem("merchantSubscriptionId");
        var merchantUserId = localStorage.getItem("merchantUserId");
        this.SubscripID = localStorage.getItem('SubscriptionID');
        var TotalAmount = localStorage.getItem("TotalAmount");
      //  const apiUrl = "https://localhost:44326/api/Ss/PhonepeRecurringDebitExecute";
       const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeRecurringDebitExecute"; // Replace with your actual API URL
        var paymentRequest = {
            merchantId: "SUBHASANONLINE",
            merchantUserId: merchantUserId,
            subscriptionId:  this.SubscripID,
            notificationId: notificationId,
            transactionId: transactionID            
        }
        this.http.post(apiUrl, paymentRequest).subscribe((response: any) => {
            debugger
            //var result = response.data.state
            var result = response.data.transactionDetails.state
            if (result == "COMPLETED") {
                this.RecurringDebitExecuteStatus();
            }
        })
    }

    RecurringDebitExecuteStatus() {
        debugger
        var merchantTransactionId = localStorage.getItem("TransactionID");
        var TotalAmount = localStorage.getItem("TotalAmount");
        if (merchantTransactionId != null) {
           // const apiUrl = "https://localhost:44326/api/Ss/PhonepeDebitExecuteStatus?merchantTransactionId=" + merchantTransactionId;
           const apiUrl = "https://subhasankalpam.in/webservices/api/Ss/PhonepeDebitExecuteStatus?merchantTransactionId=" + merchantTransactionId; // Replace with your actual API URL
            this.http.get(apiUrl).subscribe((response: any) => {
                debugger
                var result = response.data.transactionDetails.state
                this.TransStatus = result;
                if (result == "COMPLETED") {
                    this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
                    var UploadFile = new FormData();
                    UploadFile.append("UserDetails", JSON.stringify(this.UserDetails));
                    UploadFile.append("TotalAmount", TotalAmount);
                    UploadFile.append("SubscribeType", 'Recurring Subscription');
                    var url = "api/Ss/SendMailAfterPayment";
                    this.generalService.PostData(url, UploadFile).then((data: any) => {
                    })
                    this.router.navigate(['Receipt']);                    
                }
            })
        }
    }

    SendMail() {
        debugger
        this.UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
        var UploadFile = new FormData();
        UploadFile.append("UserDetails", JSON.stringify(this.UserDetails));
        UploadFile.append("TotalAmount", this.RecurringAmount);
        var url = "api/Ss/SendMailAfterPayment";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

        })
    }






    async payWithRazorpay() {
        debugger                    
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;        
        //var R = this.financial(1);
        //var S = +R;     
        var p = "1000"
        var options: any = {
            description: 'YKMART',
            //image: 'https://meatly.co.in/webservices/IMAGES/icon.png',
            currency: 'INR',
            key: 'rzp_live_VcpmG5M8UJUp01',//Ykmart key
            payment_id: n,
            payment_capture: '1',
            amount: p,
            name: this.UserName,
            prefill: {
                // email: this.LoginDetails.Email,
                contact: this.MobileNumber,
                name: this.UserName
            },

            theme: {
                color: '#183486'
            },
            modal: {
                ondismiss: function () {
                    alert('dismissed')
                }
            }
        }
        try {
            let data = (await Checkout.open(options));
            console.log(data.response + "AcmeCorp");
            console.log(JSON.stringify(data))
            debugger
            this.generalService.ShowAlert("SUCCESS", 'Your Order has Placed Successfully', "Success");                              
        } catch (error) {
            //it's paramount that you parse the data into a JSONObject
            //let errorObj = JSON.parse((error as any)['code']);            
        }
    }


    openNewTab() {
        debugger
        window.open(this.url, '_blank');
    }

    openNewTab111(url: string) {
        debugger
        // Create an anchor element
        const anchor = document.createElement('a');

        // Set the href attribute to the URL
        anchor.href = url;

        // Set the target attribute to '_blank' to open in a new tab
        anchor.target = '_blank';

        // Optionally, you can add other attributes or styles to the anchor element if needed

        // Append the anchor element to the document body (or another desired location)
        document.body.appendChild(anchor);

        // Click the anchor programmatically to open the link in a new tab
        anchor.click();

        // Remove the anchor from the document body after clicking (optional)
        document.body.removeChild(anchor);
    }

    onSubmit1() {
        // Assuming your backend endpoint to get subscription details is at 'http://localhost:yourport/api/razorpay/getsubscriptiondetails'
        this.http.get<any>('https://localhost:44326/api/Razorpay/GetSubscriptionDetails')
            .subscribe(
                (response) => {
                    // Extract the short URL from the response
                    const shortUrl = response.short_url;

                    // Open the short URL in a new tab/window
                    window.open(shortUrl, '_blank');
                },
                (error: HttpErrorResponse) => {
                    console.error('Error fetching subscription details:', error);
                    // Handle error as needed
                }
            );
    }
    


}
