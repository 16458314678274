<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Contact Us</b></li>
            <div>Contact Us</div>

        </ul>
    </div>
</nav>
<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form contac">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <br /><br />
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i> <span>Location:</span>Subhasankalpam Foundation
                                    PlatNO 10 New SBH Colony,
                                    Hyderabad, Telangana,
                                    India 50026
                                    CIN: U85300YG2022NPL164051
                                </li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:Mail2subhasankalpam@gmail.com"><span>Email:</span>Mail2subhasankalpam@gmail.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:8328080097"><span>Call:</span>+91 8328080097</a> <a href="tel:7794916299"> +91 7794916299</a></li>
                                <li>
                                    <i class="fas fa-fax"></i><span>Bank Details:</span>
                                    Bank Name :&nbsp;HDFC BANK<br />
                                    Account No :&nbsp;50200070564183<br />
                                    IFSC Code &nbsp;&nbsp;&nbsp;:&nbsp;HDFC0000377<br />
                                    PAN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;AB1CS8158H

                                    <!--<div class="row">
        <div class="col-lg-5">
            <p>Bank Name </p>
            <p>Account No </p>
            <p>IFSC Code </p>
            <p>PAN </p>
        </div>
        <div class="col-lg-7">
            <p> :&nbsp;HDFC BANK</p>
            <p> :&nbsp;50200070564183</p>
            <p> :&nbsp;HDFC0000377</p>
            <p> :&nbsp;AB1CS8158H</p>
        </div>
    </div>-->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <br />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1411272977903!2d78.50497907390756!3d17.452958100915374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b031701096f%3A0xcbca36dbe46dceff!2sSubhasankalpam%20Foundation!5e0!3m2!1sen!2sin!4v1705994977294!5m2!1sen!2sin" width="1100" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>
<style>
    body{
        overflow-x:hidden;
    }
</style>
