<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" [ngClass]="{'active': isMenuOpen, 'sticky': isSticky}">
    <div class="navbar">
        <div class="container-fluid nav-container bgg"   >
            <input class="checkbox" type="checkbox" name="" id=""  [(ngModel)]="isMenuOpen"  />
            <div class="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
            </div>
            <div class="brand-name">
                <img src="../../../../assets/img/subhasankalpam/logo.webp" class="logoo brand1" alt="Subha Sankalpam" />  Subha Sankalpam  <img src="../../../../assets/img/subhasankalpam/logo.webp" class="logoo brand"alt="Subha Sankalpam" />
            </div>
            <div class="menu-items">

                <ul [ngClass]="{'show': isMenuOpen}">
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/home"><i class="fas fa-home"></i> Home</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/about"><i class="fas fa-info-circle"></i> About</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/"><i class="fas fa-donate"></i> Donate</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/Blogs"><i class="fas fa-newspaper"></i> Blogs</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/Gallery"><i class="fas fa-images"></i> Gallery</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/contact"><i class="fas fa-address-book"></i> Contact</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/Volunteer"><i class="fas fa-user"></i> Volunteer</span></li>
                </ul>
            </div>

            <div>
                <button class="donate" routerLink="/">Donate Now</button>
            </div>
            <div class="phn">
                <i class="fa-solid fa-phone" style="color:white"></i>
                <a href="tel:8328080097">+91 8328080097</a>
                <a href="tel:7794916299">/ 7794916299</a>
            </div>
            <div class="mail">
                <a href="mailto:Mail2subhasankalpam@gmail.com">
                    <i class="far fa-envelope " style="color:white"></i> Mail2subhasankalpam@gmail.com
                </a>
            </div>

        </div>
    </div>
</nav>
<!--<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" [ngClass]="{'active': isMenuOpen, 'sticky': isSticky}">
    <div class="navbar">
        <div class="container-fluid nav-container bgg">
            <input class="checkbox" type="checkbox" [(ngModel)]="isMenuOpen" />
            <div class="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
            </div>
            <div class="brand-name">
                <img src="../../../../assets/img/subhasankalpam/logo.webp" class="logoo brand1" alt="Subha Sankalpam" /> Subha Sankalpam
                <img src="../../../../assets/img/subhasankalpam/logo.webp" class="logoo brand" alt="Subha Sankalpam" />
            </div>
            <div class="menu-items">
                <ul [ngClass]="{'show': isMenuOpen}">
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/home"><i class="fas fa-home"></i> Home</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/about"><i class="fas fa-info-circle"></i> About</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/"><i class="fas fa-donate"></i> Donate</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/Blogs"><i class="fas fa-newspaper"></i> Blogs</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/Gallery"><i class="fas fa-images"></i> Gallery</span></li>
                    <li class="nav-item" (click)="closeMenu()"><span class="nav-link" routerLink="/contact"><i class="fas fa-address-book"></i> Contact</span></li>
                </ul>
            </div>
            <div>
                <button class="donate" routerLink="/">Donate Now</button>
            </div>
            <div class="phn">
                <i class="fa-solid fa-phone" style="color:white"></i>
                <a href="tel:8328080097">+91 8328080097</a>
                <a href="tel:7794916299">/ 7794916299</a>
            </div>
            <div class="mail">
                <a href="mailto:Mail2subhasankalpam@gmail.com">
                    <i class="far fa-envelope " style="color:white"></i> Mail2subhasankalpam@gmail.com
                </a>
            </div>
        </div>
    </div>
</nav>-->
