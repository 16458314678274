<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Gallery</b></li>
            <div>Gallery</div>

        </ul>
    </div>
</nav>
<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Our  <span>Gallery</span></h2>
            <p>
                "Capturing Compassion: A Visual Journey through Our Gallery Showcasing Groceries, Blankets, Water Dispensers, and Books Donation Initiatives"
            </p>
        </div>
        <div class="row">
            <!--<div class="col-lg-3">
                <a href="assets/img/Logo.png" target="_blank">
                    <img src="assets/img/favicon.png" style="width:100%;height:200px;" />
                </a>
            </div>-->
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0031.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0031.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0032.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0032.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0033.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0033.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0034.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0034.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0035.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0035.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0036.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0036.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0037.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0037.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0038.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0038.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0039.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0039.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0040.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0040.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0041.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0041.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0042.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0042.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0043.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0043.webp" />
                </a>
            </div>

            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0045.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0045.webp" />
                </a>
            </div>

            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0047.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0047.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0048.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0048.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0049.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0049.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0050.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0050.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0051.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0051.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0052.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0052.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0053.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0053.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0054.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0054.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0055.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0055.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0056.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0056.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0057.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0057.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0058.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0058.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0059.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0059.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0060.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0060.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0061.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0061.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0062.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0062.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0063.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0063.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0064.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0064.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0065.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0065.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0066.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0066.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0067.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0067.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0068.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0068.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0069.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0069.webp" />
                </a>
            </div>
            <div class="col-lg-3">
                <a href="assets/img/subhasankalpam/IMG-20240118-WA0070.webp" target="_blank">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0070.webp" />
                </a>
            </div>
        </div>
    </div>
    <!--<div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>-->
</div>
