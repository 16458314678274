import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    // Navbar Sticky
    isSticky: boolean = false;
    isMenuOpen: boolean = false;
    classApplied: boolean = false;

    constructor(private viewportScroller: ViewportScroller, public router: Router) {
        // Constructor code here
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.isSticky = scrollPosition >= 50;
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() { }

    donate() {
        this.router.navigate(['/donate']);
        this.closeMenu();
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    home() {
        this.router.navigate(['/']);
        this.closeMenu();
    }

    about() {
        this.router.navigate(['/about']);
        this.closeMenu();
    }

    Blogs() {
        this.router.navigate(['/Blogs']);
        this.closeMenu();
    }

    Gallery() {
        this.router.navigate(['/Gallery']);
        this.closeMenu();
    }

    contact() {
        this.router.navigate(['/contact']);
        this.closeMenu();
    }

    closeMenu() {
        this.isMenuOpen = false;
    }

    closeMenuAndNavigate(link: string) {
        this.closeMenu();
        this.router.navigate([link]);
    }
}
