<div class="strategy-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/subhasankalpam/IMG-20240118-WA0069.webp" style="border-radius:5px"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">

                        <h2>What  <span>We Do</span></h2>
                        <p>SubhaSankalpam Foundation focuses on identifying those who are in need and providing them with fresh, wholesome meals. We understand the crucial importance of nutrition for overall health and well-being. By offering nutritious and delicious food options, we aim to not only satisfy hunger but also contribute to the long-term development and potential of individuals.</p>
                        <p>We firmly believe that solving the issue of hunger requires a community-driven approach. We invite you to join us in making a tangible impact by investing your time, skills, and resources. There are various ways you can contribute to our cause:</p>

                    </div>
                    <h4> Types Of Donations</h4><br />
                                                <ul class="features-list">
                                                    <li><i class="fa fa-check"></i>Food</li>
                                                    <li><i class="fa fa-check"></i>Blankets</li>
                                                    <li><i class="fa fa-check"></i>Groceries</li>
                                                    <li><i class="fa fa-check"></i>Books</li>
                                                    <li><i class="fa fa-check"></i>Water Dispenser</li>
                                                    <!--<li><i class="fa fa-check"></i>Digital Marketing & Branding</li>-->
                                                </ul>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>-->
</div>

 <style>
     @media only screen and (max-width:600px){
         .strategy-image {
             width: auto;
             /* height: 74%; */
              margin-left: 0px; 
             margin-top: 0px;
             background-image: url(IMG-20240118-WA0048.webp);
             background-size: cover;
             background-position: center center;
             background-repeat: no-repeat;
         }
     }
 </style>  
