<div class="demo-modal-panel">
    <div class="stickyy-container">
        <ul class="stickyy">
            <li>
                <a href="mailto:Mail2subhasankalpam@gmail.com" target="_blank" class="transition-link">
                    <img src="assets\img\icns\mail.png" class="transition-image " />
                    <div class="hidden-text">
                        <p>Mail</p>
                    </div>
                </a>
            </li>
            <li>
                <a href="https://wa.me/8328080097" target="_blank" class="transition-link">
                    <img src="assets\img\icns\whatsapp.png" class="transition-image " />
                    <div class="hidden-text">
                        <p>WhatsApp</p>
                    </div>
                </a>
            </li>
            <li>
                <a href="tel:+918328080097" class="transition-link">
                    <img src="assets\img\icns\phone.png" class="transition-image " />
                    <div class="hidden-text">
                        <p>Call</p>
                    </div>
                </a>
            </li>
            <li>
                <a href="donate.html" class="transition-link">
                    <img src="assets\img\icns\donate1.png" class="transition-image icon " />
                    <div class="hidden-text">
                        <p>Call</p>
                    </div>
                </a>
            </li>
        </ul>
    </div>



    <!--<button class="sidebar-demo-control" (click)="toggleClass()">
        <span>Demos</span>
    </button>-->
</div>

<!--<div class="example-demo-modal" [class.active]="classApplied">
    <div class="inner">
        <ul>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-1.jpg" alt="image">
                    <span>Main Demo</span>
                    <a href="/" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-2.jpg" alt="image">
                    <span>Slider Demo</span>
                    <a href="/home-two" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-3.jpg" alt="image">
                    <span>Video Demo</span>
                    <a href="/home-three" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-4.jpg" alt="image">
                    <span>Ripple Home</span>
                    <a href="/home-four" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-5.jpg" alt="image">
                    <span>Creative Demo 1</span>
                    <a href="/home-five" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-6.jpg" alt="image">
                    <span>Creative Demo 2</span>
                    <a href="/home-six" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-7.jpg" alt="image">
                    <span>Creative Demo 3</span>
                    <a href="/home-seven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-8.jpg" alt="image">
                    <span>Animation Text Demo</span>
                    <a href="/home-eight" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-9.jpg" alt="image">
                    <span>Particles Demo 1</span>
                    <a href="/home-nine" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-10.jpg" alt="image">
                    <span>Creative Demo 4</span>
                    <a href="/home-ten" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-11.jpg" alt="image">
                    <span>Creative Demo 5</span>
                    <a href="/home-eleven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-12.jpg" alt="image">
                    <span>Gradient Demo</span>
                    <a href="/home-twelve" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-13.jpg" alt="image">
                    <span>Particles Demo 2</span>
                    <a href="/home-thirteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-details.jpg" alt="image">
                    <span>Blog Details</span>
                    <a href="/blog-details" class="link-btn"></a>
                </div>
            </li>
        </ul>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control" (click)="toggleClass()">
            <i class="fas fa-times"></i>
        </button>
        <div class="title">View Demo</div>
    </div>
</div>-->
