<!--<div class="container">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-xs-6">
                    <div class="mobile_call">
                        <i class="bi bi-envelope-fill  " style="color:white"></i>
                        <a href="mailto:info@subhasankalpam.com" style="color:white"> info@subasankalpam.com</a>

                    </div>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <div class="mobile_phone">
                        <i class="bi bi-phone-fill phone-icon" style="color:white"></i>
                        <span style="color:white"> +91 9876543210</span>
                    </div>
                </div>
                <div class="col-lg-5 col-xs-6"></div>

                <div class="col-lg-1 col-6 col-xs-6">
                    <nav id="navbar" class="navbar">
                        <ul>
                            <li><a class="nav-link scrollto active" href="index.html">Home</a></li>
                            <li><a class="nav-link scrollto" href="about.html">About Us</a></li>
                            <li><a class="nav-link scrollto" href="activities.html">Our Activities</a></li>
                            <li><a class="nav-link scrollto" href="donate.html">Donate</a></li>
                            <li><a class="nav-link scrollto" href="gallery.html">Gallery</a></li>
                            <li><a class="nav-link scrollto" href="videogallery.html">Video Gallery</a></li>
                            <li><a class="nav-link scrollto" href="faq.html">FAQ'S</a></li>
                            <li><a class="nav-link scrollto" href="news.html">Latest News & Updates</a></li>
                            <li><a class="nav-link scrollto" href="contact.html">Contact Us</a></li>
                        </ul>
                        <i class="bi bi-list mobile-nav-toggle left mobile_bar"></i>
                    </nav> 
                </div>


            </div>
        </div>
    </div>-->
<div class="main-banner item-bg-one">
    <section id="hero">
        <div class="hero-container">
            <div id="heroCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

                <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

                <div class="carousel-inner" role="listbox">

                    <!-- Slide 1 -->
                    <div class="carousel-item active" style="background-image: url('assets/img/banner/Subh_Banner1.png');">
                        <div class="carousel-container">
                            <div class="carousel-content container">
                                <h2 class="animate__animated animate__fadeInDown">Welcome to <span>Subha Sankalpam</span></h2>
                                <p class="animate__animated animate__fadeInUp">
                                    <span class="highlight">A Thought For A Good Cause</span><br />
                                    "Make a meaningful impact through your donations of <span class="highlight">Books, Blankets, Food, Groceries, and Water dispensers</span>. Empower minds, provide warmth, fight hunger, and ensure access to clean water. Join us in creating positive change for those in need."
                                </p>
                                <a href="about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                    </div>

                    <!-- Slide 2 -->
                    <div class="carousel-item" style="background-image: url('assets/img/banner/Subh_Banner2.png');">
                        <div class="carousel-container">
                            <div class="carousel-content container">
                                <h2 class="animate__animated animate__fadeInDown">More Locations of Annadanam, Which We Have Done Multiple Times</h2>
                                <p class="animate__animated animate__fadeInUp">"Extend a helping hand across Hyderabad with our repeated Annadanam initiatives at key locations like Niloufer Hospital, MNJ Cancer Hospital, Gandhi Hospital, and more. Nourishing lives at Devnar Home for Blind, Navjeevan Orphanage, and Aman Vedika Orphanage. Join us in spreading warmth and care at Manchikalalu Orphanage and Mother Teresa Old Age Home."</p>
                                <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                    </div>

                    <!-- Slide 3 -->
                    <!--<div class="carousel-item" style="background-image: url('assets/img/slide/slide-3.jpg');">
                        <div class="carousel-container">
                            <div class="carousel-content container">
                                <h2 class="animate__animated animate__fadeInDown">Sequi ea ut et est quaerat</h2>
                                <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                                <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                            </div>
                        </div>
                    </div>-->

                </div>

                <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                </a>
                <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                </a>

            </div>
        </div>
    </section>

    <!--<div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>The Best <span>Digital</span> Experience</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
                </div>
            </div>
        </div>
    </div>-->
</div>

<app-welcome></app-welcome>

<!--<app-about></app-about>-->

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<!--<app-cta></app-cta>-->

<!--<app-skill></app-skill>-->

<!--<app-funfacts></app-funfacts>-->

<!--<app-team></app-team>-->

<!--<app-how-we-work></app-how-we-work>-->

<!--<app-services></app-services>-->

<app-why-we-different></app-why-we-different>

<!--<app-work></app-work>-->

<!--<app-pricing></app-pricing>-->

<app-faq></app-faq>

<!--<app-feedback></app-feedback>-->

<!--<app-blog></app-blog>-->

<!--<app-partner></app-partner>-->

<!--<app-subscribe></app-subscribe>-->

<!--<app-contact></app-contact>-->
<style>
    @media only screen and (max-width:600px) {
        .main-banner {
            height: 100%;
            padding-top: 60px;
            padding-bottom: 1px;
        }
    }
</style>
