<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked <span>Question</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                How did SubhaSankalpam Foundation begin?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>
                                    SubhaSankalpam Foundation started in 2017 when one of the founders encountered an elderly woman in need of food during his travels. This encounter inspired the foundation's mission to serve fresh, homemade food to those facing hunger.
                                </p>
                            </div>
                        </div>

                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                What does the Global Hunger Index (GHI) indicate about hunger in India?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>
                                    According to the 2023 Global Hunger Index, India has a rank of 111th out of 125 countries, indicating a serious level of hunger. The GHI helps track hunger comprehensively and highlights areas where additional efforts are needed to combat hunger.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                Can I volunteer with SubhaSankalpam Foundation even if I'm not experienced in cooking or organizing events?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>
                                    Absolutely! We welcome individuals with diverse skills and interests. Whether you have a passion for cooking, event organizing, or simply want to lend a helping hand, there are various volunteer opportunities available for everyone.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                How are donations used by SubhaSankalpam Foundation?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>
                                    Donations to SubhaSankalpam Foundation directly support our efforts to provide fresh food to those in need. The funds are utilized for sourcing fresh ingredients, maintaining distribution networks, and expanding our reach to serve more communities.
                                </p>
                            </div>
                        </div>
                         <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                How did SubhaSankalpam Foundation begin?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>
                                    SubhaSankalpam Foundation started in 2017 when one of the founders encountered an elderly woman in need of food during his travels. This encounter inspired the foundation's mission to serve fresh, homemade food to those facing hunger.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
              
                <div>
        <img src="https://img.freepik.com/free-vector/faqs-concept-illustration_114360-5185.jpg?size=338&ext=jpg&ga=GA1.1.553209589.1714953600&semt=sph" alt="image"/>
    </div>
            </div>
            <div class="col-lg-12">
                <button style="float: inline-end;color:white" routerLink="/contact" class="btn-get-started animate__animated animate__fadeInUp scrollto">Contact Us</button>

            </div>
        </div>
    </div>
</div>
